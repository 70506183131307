
// Variables

//
// custom-variables
//

// Vertical Sidebar - Default Light
$sidebar-bg: #ffffff;
$sidebar-menu-item-color: #333;
$sidebar-menu-sub-item-color: #ffffff;
$sidebar-menu-item-icon-color: #333;
$sidebar-menu-item-hover-color: #FCB630;
$sidebar-menu-item-active-color: #053DC8;
$sidebar-width:  240px;
$sidebar-collapsed-width:  70px;
$sidebar-width-sm:  160px;

// Vertical Sidebar - Dark
$sidebar-dark-bg: #2a3142; //2c313a
$sidebar-dark-menu-item-color: #a6b0cf;
$sidebar-dark-menu-sub-item-color: #79829c;
$sidebar-dark-menu-item-icon-color: #8699ad;
$sidebar-dark-menu-item-hover-color: #FCB630;
$sidebar-dark-menu-item-active-color: #ffffff;
$sidebar-menu-item-active-bg: #2f374a;

// Topbar - Deafult Light
$header-height: 70px;
$header-bg: #36394c;
$header-item-color: #dee2e6;
$menu-item-active-color: #053DC8;

// Topbar - Dark
$header-dark-bg: #2a3142;
$header-dark-item-color: #a6a9b0;

// Topbar Search
$topbar-search-bg: #353e53;

// Footer
$footer-height: 60px;
$footer-bg: #36394c;
$footer-color: #a6b0cf;

// Horizontal nav
$topnav-bg:   #2a3142;

// Right Sidebar
$rightbar-width:  280px;

// Brand 
$navbar-brand-box-width: 240px;

// Boxed layout width
$boxed-layout-width:    1300px;
$boxed-body-bg:       #191c27;

// Secondary font
$font-family-secondary: 'Poppins', sans-serif;

// Font Weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// apex charts
$apex-grid-color: #32394e;


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system


$white:    #fff;
$gray-100: #323a4e;
$gray-200: #32394e;
$gray-300: #2e3648;
$gray-400: #e9ecef;
$gray-500: #9ca8b3;
$gray-600: #3c445a;
$gray-700: #f6f6f6;
$gray-800: #eff2f7;
$gray-900: #f8f9fa;
$black:    #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);


$blue:    #053DC8;
$indigo:  #564ab1;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #ec4561;
$orange:  #f1734f;
$yellow:  #f8b425;
$green:   #02a499;
$teal:    #050505;
$cyan:    #38a4f8;


$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-500;
$dark:          $gray-800;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "pink":       $pink,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900;
$yiq-text-light:            $white;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                false;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-prefers-reduced-motion-media-query:   true;
$enable-hover-media-query:                    false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true;
$enable-pointer-cursor-for-buttons:           true;
$enable-print-styles:                         true;
$enable-responsive-font-sizes:                false;
$enable-validation-icons:                     true;
$enable-deprecation-messages:                 true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);


// Body
//
// Settings for the `<body>` element.

$body-bg:                   #222736;
$body-color:                $gray-400;


// Links
//
// Style anchor elements.

$link-color:                              theme-color("primary");
$link-decoration:                         none;
// $link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

// @include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           24px;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                lighten($gray-200, 1%);

$border-radius:               .25rem;
$border-radius-lg:            .5rem;
$border-radius-sm:            .2rem;

$rounded-pill:                50rem;

$box-shadow-sm:               0px 0px 13px 0px rgba(36, 36, 41, 0.44);
$box-shadow:                  0 0.75rem 1.5rem rgba(18,38,63,.03);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);


$component-active-bg:         $primary;

$caret-width:                 .3em;
$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * .85;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  (
    (21 9),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Roboto', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              .875rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            500;
$font-weight-bolder:          500;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      calc($spacer / 2);
$headings-font-family:        null;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              null;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              $font-size-base * 1.25;
$lead-font-weight:            300;

$small-font-size:             80%;

$text-muted:                  $gray-500;

$blockquote-small-color:      $gray-500;
$blockquote-small-font-size:  $small-font-size;
$blockquote-font-size:        $font-size-base * 1.25;

$hr-border-color:             lighten($gray-200, 4%);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .3rem;

$table-color:                 lighten($gray-500, 4%);
$table-bg:                    null;
$table-accent-bg:             rgba(0,0,0,.05);
$table-hover-color:           $table-color;
$table-hover-bg:              $gray-100;
$table-active-bg:             $table-hover-bg;

$table-border-width:          $border-width;
$table-border-color:          $border-color;

$table-head-bg:               $gray-200;
$table-head-color:            $gray-400;

$table-dark-color:            $gray-400;
$table-dark-bg:               $gray-200;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-color:      $table-dark-color;
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($table-dark-bg, 7.5%);

$table-striped-order:         odd;

$table-caption-color:         $text-muted;

$table-bg-level:              -9;
$table-border-level:          -6;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .47rem;
$input-btn-padding-x:         .75rem;
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .15rem;
$input-btn-focus-color:       rgba($component-active-bg, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
$input-btn-line-height-lg:    $line-height-lg;

$input-btn-border-width:      $border-width;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-family:             $input-btn-font-family;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-font-size-sm:            $input-btn-font-size-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-font-size-lg:            $input-btn-font-size-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-border-width:            $input-btn-border-width;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width:             $input-btn-focus-width;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

$btn-link-disabled-color:     $gray-600;

$btn-block-spacing-y:         .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius-sm:        $border-radius-sm;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-primary: '#333'