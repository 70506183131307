/* @import url("https://font.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap"); */

  /* styling scrollbars */
  
::-webkit-scrollbar       {background-color:#fff;width:16px}
::-webkit-scrollbar-track {background-color:#fff}
::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:4px solid #fff}
  
  /* general styles */
  
  * {
    margin: 0;
    padding: 0;
  }
  
  body {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }
  
  .text-primary-p {
    color: #a5aaad;
    font-size: 14px;
    font-weight: 700;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .text-title {
    color: #2e4a66;
  }
  
  .text-lightblue {
    color: #469cac;
  }
  
  .text-red {
    color: #cc3d38;
  }
  
  .text-yellow {
    color: #a98921;
  }
  
  .text-green {
    color: #3b9668;
  }
  
  .is-invalid{
    color: #cc3d38;
    font-size: 10px;
  }
  
  .capitalize {
    text-transform: capitalize;
  }

  .form-group .title{
    font-weight: 700;
  }

  .col-info{
    margin-bottom: 1rem !important;
  }

  .btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none !important;
  }
  
  input:focus, .form-control:focus{
    background-color: #fff;
    border-color: #333;
    outline: 1;
    border: 1px solid #333;
    box-shadow: none !important;
  }

  .table-title{
    display: flex;
    padding: 0rem 2rem;
    flex-direction: column;
  }
  
  .table-title .title{
    font-size: 17px;
  }
  
  .table-title .desk{
    font-size: 12px;
  }

  .swal2-styled.swal2-confirm{
    background-color: #FCB630 !important;
  }

  
  .swal2-styled.swal2-confirm:focus{
    outline: 0;
    box-shadow: none !important;
  }
  
  .img-dash-load{
    width: 10%;
    position: absolute;
    left: 45%;
    top: 45%;
  }

  .img-profil-load{
    width: 10% !important;
    position: absolute;
    left: 45%;
    top: 45%;
  }