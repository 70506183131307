body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-primary {
  background-color: #053DC8;
  color: #fff;
  padding: 6px 16px;
  font-size: 13px;
}

.button-primary:hover {
  color: #fff
}

.button-secondary {
  background-color: #333;
  color: #fff;
  padding: 6px 16px;
  font-size: 13px; 
}

.button-secondary:hover {
  color: #fff
}

.button-green {
  background-color: #16935B;
  color: #fff;
  padding: 6px 16px;
  font-size: 13px;
}

.button-green:hover {
  color: #fff
}

.button-white-orange {
  background-color: #fff;
  color: #fd7e14;
  padding: 6px 16px;
  font-weight: bold;
  font-size: 13px;
  margin: 16px;
}

.button-white-green {
  background-color: #fff;
  color: #16935B;
  padding: 6px 16px;
  font-weight: bold;
  font-size: 13px;
  margin: 16px;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.country-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.country-logo {
  width: 35px;
  height: 20px;
  margin-right: 8px;
}

.selected-logo {
  width: 35px;
  height: 20px;
  margin-right: 8px;
}

.css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: #053DC8 !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.css-t3ipsp-control {
  background-color: #053DC8 !important;
}