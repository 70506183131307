#sidebar-a {
  background: #ffffff;
  grid-area: sidebar;
  padding: 12px;
  transition: left 0.25s;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 101;
}

.sidebar__title {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  margin-bottom: 10px;
  flex-direction: column;
}
.tes{
  display: flex;
}

.sidebar__img {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sidebar__title > div > img {
  width: 155px;
  object-fit: contain;
  padding-top: 0.5rem;
}

.sidebar__title > div > h1 {
  font-size: 13px;
  display: inline;
  padding-top: 1rem;
  text-align: center;
}

.sidebar__title > i {
  font-size: 12px;
  display: none;
}
.sidebar__menu {
  flex-grow: 1;
}
.sidebar__menu > h2 {
  color: #3ea175;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 0px 15px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.sidebar__link:hover{
  color: rgb(255 197 92);
}

.active_menu_link {
  color: #053DC8;
  border-right: 5px #053DC8 solid;
}

.active_menu_link a {
  color: #053DC8 !important;
  /* font-weight: 700; */
}

.sidebar__link > a {
  font-size: 12px;
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
}

.sidebar__link > i {
  margin-right: 10px;
  font-size: 15px;
}

.sidebar__link > svg {
  margin-right: 10px;
  font-size: 15px;
}

.sidebar__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar__footer > span {
  font-size: 12px;
  text-decoration: none;
  color: #333;
  font-weight: 700;
}

.sidebar__footer > img {
  width: 35%;
}

.sidebar__responsive {
  display: flex !important;
  flex-direction: column;
  justify-content: space-around;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

@media only screen and (max-width: 769px) {
  #sidebar-a {
    display: none;
    position: absolute !important;
    height: 100%;
  }
  .sidebar__title > i {
    display: inline;
    position: absolute;
    right: 0;
    margin-right: 15px;
    top: 0;
    margin-top: 15px;
  }
}

.title-statcard {
  background-color: #FCB630;
  padding: 5px 15px 5px 15px;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.me-1 {
  margin-right: 7px;
}

.mb-30{
  margin-bottom: 30px;
}