/* body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a:hover{
  color: #333 !important;
}
.account-pages{
  background-color: #053DC8 !important;
  height: 100%;
  padding-top: 6rem;
}
.bg-primary1 {
  background-color: #333333 !important;
}
.btn-primary1 {
  color: #f5f5f5 !important;
  background-color: #333333 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-redap {
  background-color: #053DC8 !important;
}
.bg-custom-light {
  background-color: rgb(245, 245, 245);
}
.text-primary{
  background-color: #333333 !important;
}
.sidebar {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1080;
} 

.cs-border-top {
  border-top: 1px solid rgba(255,255,255,.15);
}

.cs-border-bottom {
  border-bottom: 1px solid rgba(255,255,255,.15);
}

.cs-nav-link {
  color: rgba(255,255,255,.8);
  text-decoration: none;
}

.cs-nav-link svg {
  color: rgba(255,255,255,.3);
}

.cs-nav-link:hover {
  color: white;
  text-decoration: none;
}

.cs-nav-link:hover svg {
  color: white;
}

.cs-nav-link:focus {
  color: white;
  text-decoration: none;
}

.cs-nav-link:focus svg {
  color: white;
}

.cs-nav-link.active {
  color: white;
}

.cs-nav-link.active svg {
  color: white;
}
.cs-nav-link span{
  color: #fff;
}
.side-footer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-footer img{
  width: 130px;
}
.side-footer span{
  font-size: 13px;
}

.header-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-wrap img{
  width: 130px;
}
.nav-side-bar{
  padding-left: 2rem;;
}
.col-wrapper{
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0em 0rem;
}
.col-wrapper .text-start{
  display: flex;
}
.col-wrapper .text-end{
  display: flex;
}
.span-text-update{
  background: transparent linear-gradient(
    141deg
    , #053DC8 0%, #FFA127 100%) 0% 0% no-repeat padding-box;
  padding: 8px;
  border-radius: 15px;
  color: #fff;
  font-weight: 500;
}
.search-wrapper{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.search-wrapper input{
  width: 220px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D9D9D9;
  border-radius: 15px;
  padding: 8px 35px 7px 12px;
  margin-right: 10px;
}

.search-wrapper select{
  width: 210px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D9D9D9;
  border-radius: 15px;
  padding: 8px 35px 7px 12px;
  margin-right: 10px;
  margin-left: 10px;
}

.search-wrapper button{
  position: absolute;
  right: 0.5em;
  transform: translateY(-4%);
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
}
.search-wrapper input:focus{
  outline: none !important;
}
.search-wrapper select:focus{
  outline: none !important;
}
.search-wrapper button:hover{
  color: #053DC8 !important;
}
.span-text-umkm{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 8px 40px;
  border-radius: 15px;
  color: #053DC8;
  font-weight: 700;
  border: 1px solid #D9D9D9;
}
.card-title{
  font-weight: 700;
  font-size: 50px;
  margin-bottom: -0.8rem !important;
}
.disabled {
  pointer-events:none;
  opacity:0.6;        
}
.mb-35{
  margin-bottom: 15%;
}
.card-information{
  font-weight: 500;
  padding-left: 1rem;
  font-size: 1.5rem !important;
  padding-right: 1rem;
}
.card-header1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 2.2rem;
}
.card-content-wrap{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-body span{
  float: right;
  background-color: #fff;
  border-radius: 20px;
  padding: 7px 0px;
  color: #053DC8;
}
.card-body span svg{
  margin-right: 0rem !important;
}
.kt-widget31__progress{
  display: flex;
  flex-direction: column;
  padding: 0rem 0.5rem;
}

.kt-widget31__progress a{
  display: flex;
  justify-content: space-between; 
  line-height: 9px;
}
.kt-widget31__stats span{
  color: #333;
}

.progress .w10{
  width: 10%;
}
.progress .w20{
  width: 20%;
}
.progress .w40{
  width: 40%;
}
.progress .w60{
  width: 60%;
}
.progress .w80{
  width: 80%;
}
.progress .w90{
  width: 90%;
}
.progress .w100{
  width: 100%;
}
.progress-bar{
  background-color: #FCB630 !important;
}
.bg1{
  background-color: #FFA127 !important;
}
.bg2{
  background-color: #FFA127 !important;
}
.bg3{
  background-color: #053DC8 !important;
}
.bg4{
  background-color: #FCB630 !important;
}
.bf1{
  color: #FFA127 !important;
}
.bf2{
  color: #333 !important;
}
.bf3{
  color: #053DC8 !important;
}
.bf4{
  color: #FCB630 !important;
}
.bd1{
  border-color: #FFA127 !important;
}
.bd2{
  border-color: #333 !important;
}
.bd3{
  border-color: #053DC8 !important;
}
.bd4{
  border-color: #FCB630 !important;
}
.card-bdy{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px !important;
}
.card-bdy .bdy-header{
  display: flex;
  justify-content: flex-end;
}
.card-bdy .bdy-header a{
  background-color: #fff;
  color: #053DC8;
  border-radius: 12px;
  margin: 10px;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
}

.kt-widget12__progress{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  padding-top: 1rem;
}

.kt-widget12__progress .progress{
  width: 70%;
  height: 1.5rem;
  border-radius: 0;
}
.kt-widget12__progress span{
  color: #333;
}
.kt-widget12__progress .kt-widget12__on{
  width: 23%;
}
.kt-widget12__progress .kt-widget12__stat{
  width: 12%;
  font-weight: 700;
  padding-left: 5px;
}
.kt-widget12__progress .kt-widget12__stat1{
  width: 20%;
  font-weight: 700;
  padding-left: 5px;
}
.kt-widget12__progress .kt-widget12__stat2{
  font-size: 20px;
  width: 12%;
  font-weight: 700;
}

.kt-widget14__progress{display: flex;
  line-height: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 200px;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.kt-widget14__progress .kt-widget14__stat{
  font-size: 25px;
  font-weight: 700;
}

.kt-widget14__circle{
  width: 120px;
  height: 120px;
  border: 4px dashed #FCB630;
  border-radius: 80px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kt-widget14__progress span{
  float: right;
  background-color: #fff;
  color: #333;
}

.table-view .col{
  background-color: #fff;
  margin: 1rem 2rem;
  padding: 0rem;
  border-radius: 20px;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #e8e8e8;
}

.table-custom1 tbody tr{
  height: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px 0px #0000000b;
  opacity: 1;
  border: 3px solid transparent;
  vertical-align: middle;
}

/* .table-custom1 tbody tr td{
  text-align: center;
} */
.table-custom1 tbody tr .td-usaha{
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-custom1 tbody tr td{
  vertical-align: middle;
  align-items: center;
}
.table-custom1 tbody tr .td-usaha .span-text{
  padding-left: 0.5rem;
}
.table-custom1 tbody tr .td-usaha .span-icon-1{
  background: transparent linear-gradient(144deg, #FCB630 0%, #29E692 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  padding: 5px;
}
.table-custom1 tbody tr .td-usaha .span-icon-2{
  background: transparent linear-gradient(146deg, #FFA127 0%, #053DC8 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  padding: 5px;
}
.table-custom1 tbody tr .td-usaha .span-icon-1 svg{
  margin-right: 0rem !important;
}
.table-custom1 tbody tr .td-usaha .span-icon-2 svg{
  margin-right: 0rem !important;
}
.table-custom1 tbody tr td .span-view{
  background-color: #053DC8;
  padding: 1px 13px;
  border-radius: 14px;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  cursor: pointer;
}
.table-custom1 thead tr{
  background-color: #ffffff;
}
.table-custom1 thead tr th{
  opacity: 1;
  color: #333;
  line-height: 20px;
  font-weight: bold;
}
.table td, .table th{
  padding: 5px 10px !important;
  border-top: 0px solid !important;
}

.pagination-table li a{
  color : #333 !important;
}
.pagination-table .page-item.active .page-link{
  background-color: #b7b7b7 !important;
  border-color: #b7b7b7 !important;
}
.col-header{
  display: flex;
  background-color: #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 200px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.col-content{
  padding: 40px;
}
.col-content .col-data{
  display: flex;
  flex-direction: column;
  border: 1px solid #3333334a;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 0.8rem;
}
.col-content .col-data .span-tittle{
  font-weight: 700;
}
.col-data-sw{  
  display: flex;
  justify-content: space-between;
  padding: 4px;
  align-items: center;
}
.custom-control-input:checked~.custom-control-label::before{  
  border-color: #FCB630 !important;
  background-color: #FCB630 !important;
}
.custom-control-select:checked~.custom-control-label::before{  
  border-color: #FCB630 !important;
  background-color: #FCB630 !important;
}
.custom-switch .custom-control-label::before{
  width: 2.5rem !important;
  height: 1.5rem !important;
  border-radius: 1.5rem !important;
}
.custom-switch .custom-control-label::after{
  top: calc(0.4rem + 2px) !important;
  left: calc(-1.8rem + 0px) !important;
  width: calc(1.2rem - 4px) !important;
  height: calc(1.2rem - 4px) !important;
}
.col-data-sw .span-tittle{
  font-size: 15px;
  font-weight: 700;  
}
.col-detail{
  position: absolute;
  display: flex;
  background-color: #363636;
  border-radius: 10px;
  padding: 12px 30px;
  top: 9rem;
  width: 80%;
  height: 75px;
}
.col-detail .row{
  width: 100%;
}
.col-detail-icon{  
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-detail-icon .span-icon{
  background: transparent linear-gradient(
  146deg
  , #FFA127 0%, #053DC8 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  padding: 10px;
}
.col-detail-toko{
  display: flex;
  flex-direction: column;
  line-height: 1;
}
.col-detail-toko .span-tittle{
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.col-detail-toko .taddr{
  margin-top: 5px;
}
.col-detail-toko .taddr a{
  color: #fff !important;
}
.col-detail-toko .taddr svg{
  margin-right: 5px;
}
.col-detail-toko .span-status{
  color: #FCB630;
}

.col-detail-icon .span-icon svg{
  margin-right: 0rem !important;
}
.col-footer{
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.col-footer span{
  color: #fff;
  background-color: #053DC8;
  padding: 8px 60px;
  border-radius: 10px;
  cursor: pointer;
}

.card-login{
  box-shadow: none !important;
}

.anticon{
  vertical-align: 0em !important;
}

.echart-kategori div{
  margin-top: -40px;
}

@media (min-width: 992px){
  .stat-card1{
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .stat-card2{
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
}