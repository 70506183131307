.col-wrapper{
    margin-top: 1rem 1rem;
}

.col-wrapper .dropdown button{
    background-color : transparent !important;
    color: #333;
    font-size: 15px;
    border : 0px !important;
    box-shadow : none !important;
}

.dpw-sort .dropdown-toggle::after{
    display: none !important;
}

.dpw-unduh{
    font-size: 12px;
    background-color: #fff;
    border-radius: 10px;
}
.dpw-unduh .btn{ 
    font-size: 12px;
}

.col-wrapper .dropdown div{
    font-size: 12px;
    padding: 5px;
}

.col-wrapper .dropdown div a{
    display: flex !important;
    align-items: center;
    padding: 0rem 0.5rem !important;
}

.col-wrapper .dropdown div a svg{
    margin-right: 5px;
}

.text-start .show>.btn-success.dropdown-toggle{
    color: #333;
}

.span-rincian{
    background-color: #333;
    padding: 1px 13px;
    border-radius: 14px;
    color: #fff;
    font-weight: 700;
    font-size: 11px;
    cursor: pointer;
}
.btn-back{
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 8px;
    margin-right: 10px;
    /* padding: 6px; */
    box-shadow: 0px 0px 7px 0px #0000000b;
}
.btn-back svg{
    margin-right: 5px;
}
.btn-refresh{
    background-color: #fff;
    border: none;
    border-radius: 8px;
    margin-right: 10px;
    /* padding: 6px; */
    box-shadow: 0px 0px 7px 0px #0000000b;
}

.col-det-wrapper{
    background-color: #fff;
    border-radius: 15px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.det-content{
    padding: 10px 25px;
}
.det-content .det-title{
    font-size: 12px;
    font-weight: 700;
}
.det-content .det-title span svg{
    margin-right: 5px;
}
.det-content .det-data{
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
}
.det-content .det-data .span-tittle{
    font-weight: 700;
}
.det-content .det-data2{
    display: flex;
    padding: 5px 0px;
}
.det-content .det-start{
    display: flex;
    justify-content: space-between;
    width: 35%;
    margin-right: 5px;
}
.det-content .det-data2 .span-tittle{
    font-weight: 700;
}
.profil{
    padding: 7px;
    margin: 1.5rem 2em;
}
.profil .det-profil{
    display: flex;
    flex-direction: column;
    line-height: 23px;
}
.profil .det-profil .span-nama{
    font-size: 18px;
    font-weight: 700;
}
.profil .det-profil .span-alamat{
    font-size: 13px;
    color: rgb(97, 93, 93);
}
.span-icon-profil{
    font-size: 50px;
    background: transparent linear-gradient(
144deg
, #FCB630 0%, #29E692 100%) 0% 0% no-repeat padding-box;
    border-radius: 26px;
    padding: 15px 25px;
    color: #fff;
    margin-right: 1rem;
}
.det-setting{
    right: 41px;
    position: absolute;
}
.det-setting .dropdown{
    font-size: 12px;
    background-color: #fff;
    border-radius: 17px;
}

.det-setting .dropdown button{
    background-color: #333!important;
    color: #fff;
    font-size: 15px;
    border: 0px !important;
    box-shadow: none !important;
    padding-right: 0px;
}

.det-setting .dropdown button:after{
    color: transparent !important;
}
.det-setting .dropdown .dropdown-menu .dropdown-item{    
    font-size: 12px;
    padding: 0px 13px !important;
}

.modal-coach .col-footer{
    justify-content: space-evenly !important;
}

.modal-coach .col-footer .simpan{
    background-color: #FCB630;
}

.col-tab-mrgn{
    margin-top: 1rem;
}
.col-tab-wrapper .nav-tabs{
    border-bottom: none !important;
}

.col-tab-wrapper .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #FCB630 !important;
    background-color: #333333;
    border-bottom: 3px solid #FCB630;
    border-top: none;
    border-left: none;
    border-right: none;
}

.col-tab-wrapper .nav-tabs .nav-link {
    color: #333333;
    background-color: #fff;
    font-weight: 700;
    padding: 0.5rem 2rem !important;
}

.col-tab-wrapper .nav-tabs .nav-link:hover {
    border-bottom: 3px solid #333;
    border-top: none;
    border-left: none;
    border-right: none;
}

.btn-setting{
    background-color: #FCB630;
    color: #fff;
    border-radius: 15px;
    font-size: 12px;
    padding: 3px 10px;
}

.btn-setting div .dropdown-menu{
    font-size: 11px;
    padding: 0px;
}


.btn-setting div .dropdown-menu .dropdown-item{
    padding: 6px 15px !important;

    svg{
        margin-right: 5px;
    }
}

.image-banner{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.image-banner .img{
    width: 100%;
    height: auto;
    object-fit: contain;
}

.image-banner .img-banner{    
    top: 0% !important;
    height: 70%  !important;
    position: absolute;
    border-radius: 15px;
    object-fit: cover;
    background-size: cover;
    object-position: center;
}

.desk-html{
    overflow: auto;
    height: 150px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    padding: 10px 0px 10px 20px;
    margin-top: 0.5rem;
}

.collect-group{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .form-group{
        margin-bottom: 0rem !important;
        margin-right: 1rem;
    }
    .btn-collect{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #3333;
        border-radius: 7px;
        font-size: 10px;
        width: 100%;

        .collect-text{        
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 1rem;

            .btn-title{
                text-align: left;
            }
            
            .btn-desk{
                color: #FCB630;
                font-style: italic;
                text-align: left;
            }
        }
    }
}